<script setup lang="ts">
import BaseInput from '~/components/library/form/BaseInput.vue';
import { useDateFormat } from '@vueuse/core';
import BaseTextarea from '~/components/library/form/BaseTextarea.vue';

const userInput = ref('');
const section = ref<HTMLElement>();

// ************* TYPES ************* //
interface Props {
  isDesktop?: boolean;
}

interface State {
  user: {
    id: string;
    photo: string;
  };
  date: Date;
  text: string;
}

// ************* EMITS ************* //
const emit = defineEmits<{
  'submit:form': [State[]];
}>();

// ************* PROPS ************* //
withDefaults(defineProps<Props>(), {
  isDesktop: true,
});

const getDate = (date: string | Date) => {
  const dateObj = new Date(date);
  return useDateFormat(dateObj, 'DD/MM/YYYY, hh:mm:ss').value;
};

const chatState = ref([
  {
    user: {
      id: 'admin',
      photo:
        'https://images.pexels.com/photos/2085832/pexels-photo-2085832.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=1',
    },
    date: new Date(),
    text: 'Hi, there this chat does not store anything and is just there as a shadow chat. Come back in the future there will be a real chat here.',
  },
]);

const handleSubmission = () => {
  if (!userInput.value) return;

  const newUserInput = {
    user: {
      id: 'external',
      photo:
        'https://images.pexels.com/photos/13201286/pexels-photo-13201286.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=1',
    },
    date: new Date(),
    text: userInput.value,
  };

  chatState.value.push(newUserInput);
  // reset input field
  userInput.value = '';
  setTimeout(() => {
    section.value?.scroll({
      top: section.value.scrollHeight,
      behavior: 'smooth',
    });
  }, 300);

  emit('submit:form', chatState.value);
};
</script>

<template>
  <component
    :is="isDesktop ? 'dialog' : 'div'"
    class="dialog"
    :class="{ 'dialog--desktop': isDesktop, 'dialog--mobile': !isDesktop }"
    open
  >
    <header class="header">
      <base-img
        class="header__img"
        mode="avatar"
        :renditions="{
          url: 'https://images.pexels.com/photos/2085832/pexels-photo-2085832.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=1',
        }"
      />
      <h5 class="header__heading">Roboto AI</h5>
      <span class="header__sub-heading">BenTec</span>
    </header>
    <article ref="section" class="section">
      <transition-group tag="ul" name="fade-down" class="chat-container">
        <li
          class="list"
          :class="[
            'user',
            {
              'user--active': state.user.id === 'external',
              'user--admin': state.user.id === 'admin',
            },
          ]"
          v-for="(state, index) in chatState"
          :key="state.text + index"
        >
          <base-img
            class="list__img"
            mode="avatar"
            :renditions="{
              url: state.user.photo,
            }"
          />
          <div class="list__text">
            <div class="list__text-field">
              <base-textarea
                styling="none"
                :read-only="true"
                :model-value="state.text"
              />
            </div>
            <span class="list__time"> {{ getDate(state.date) }}</span>
          </div>
        </li>
      </transition-group>
    </article>
    <footer class="footer" :class="[{ 'footer--fixed': !isDesktop }]">
      <form @submit.prevent="handleSubmission">
        <base-input
          v-model.trim="userInput"
          class="user-input"
          placeholder="Type something"
          styling="none"
        />
        <base-button
          type="submit"
          :size="!isDesktop ? 'small' : 'smallest'"
          class="submit-btn"
          >Send
        </base-button>
      </form>
    </footer>
  </component>
</template>

<style scoped lang="scss">
.dialog {
  &--desktop {
    width: 40rem;
    height: 40rem;
    border-radius: $border-default;
    border: none;
    background-color: transparent;
    display: flex;
    flex-direction: column;
  }

  .header {
    background-color: var(--color-primary-shade-100);
    padding: 1.6rem;
    display: grid;
    grid-column-gap: 2rem;
    grid-template-columns: 4rem 1fr;
    grid-template-rows: repeat(2, 2.5rem);
    align-content: center;

    &__img {
      grid-column: 0/1;
      grid-area: 0/2;
    }

    &__heading {
      grid-column: 2/3;
    }

    &__sub-heading {
      font-size: $font-small;
      color: var(--color-grey-txt);
      grid-column: 2/3;
    }
  }

  .section {
    flex-grow: 1;
    overflow-y: auto;
    background-color: var(--color-black);

    .list {
      padding: 1.6rem;
      border-bottom: 1px solid var(--color-white);

      &.user {
        display: flex;
        gap: $spacing-small;

        &--active {
          flex-direction: row-reverse;
          background: var(--color-secondary-shade-100);

          :deep(.field),
          .list__text {
            text-align: right;
          }
        }

        &--admin {
          background-color: var(--color-primary-shade-300);
        }
      }

      &__text-field {
        max-height: 8rem;
        width: 100%;
        overflow-y: auto;
        scrollbar-width: thin;
        display: flex;

        :deep(.textarea) {
          height: auto;
        }

        @include scrollbars();
      }

      &__text {
        flex-grow: 1;
      }

      &__time {
        font-size: $font-small;
        grid-column: 2/3;
        color: var(--color-grey-txt);
      }
    }
  }

  .footer {
    &--fixed {
      position: sticky;
      bottom: 0;
      left: 0;
      width: 100%;

      form {
        box-shadow: $shadow-primary-500;
        padding: 3.2rem 1.6rem;
      }

      .submit-btn {
        width: fit-content;
      }
    }

    form {
      background-color: var(--color-primary-shade-100);
      display: flex;
      align-items: center;
      margin-top: auto;
      padding: 1.6rem;

      .user-input {
        flex-grow: 1;

        :deep(.field) {
          @include respond(phone) {
            font-size: $font-large;
          }
        }
      }

      .submit-btn {
        flex-shrink: 0;
      }
    }
  }
}
</style>
