<template>
  <div class="textarea" :class="{ error }">
    <label v-if="label" class="textarea__label" :for="uuid">{{ label }}</label>
    <textarea
      :id="uuid"
      :name="uuid"
      :value="modelValue"
      @input="$emit('update:modelValue', $event.target.value)"
      :placeholder="placeholder"
      :readonly="readOnly"
      :aria-invalid="!!error"
      class="textarea__field styling"
      :class="[
        { 'textarea__field--error': error, textarea: true },
        { none: 'styling--none', default: 'styling--default' }[styling],
      ]"
    />
    <BaseErrorMessage
      v-if="error"
      :id="`${uuid}-error`"
      :class="{ 'error-msg': error }"
    >
      {{ error }}
    </BaseErrorMessage>
  </div>
</template>

<script lang="ts" setup>
import { v4 as createUid } from 'uuid';

// ************* TYPES ************* //
interface Props {
  label?: string;
  placeholder?: string;
  styling?: 'default' | 'none';
  modelValue?: string;
  error?: string;
  readOnly?: boolean;
}

defineEmits<{
  'update:modelValue': [string];
}>();

// ************* PROPS ************* //
withDefaults(defineProps<Props>(), {
  styling: 'default',
});

const uuid = createUid();
</script>

<style lang="scss" scoped>
.textarea {
  position: relative;
  display: flex;
  flex-direction: column;
  @mixin transform-up {
    font-size: $font-small;
    transform: translateY(-60%);
    top: 0;
  }

  &.error {
    .textarea {
      &__label {
        color: var(--color-error);
        &::before {
          background-color: var(--color-black);
        }
      }
    }
  }

  &:focus-within {
    .textarea {
      &__label {
        @include transform-up;

        &::before {
          content: '';
          position: absolute;
          z-index: -1;
          height: 62%;
          width: 100%;
          top: 0;
          left: 0;
          background-color: var(--color-black);
        }
      }

      &__field {
        &::placeholder {
          visibility: visible;
        }
      }
    }
  }

  &__label {
    transition: $base-transition;
    position: absolute;
    top: 15%;
    border-radius: 0.5rem;
    left: 3rem;
    font-size: $font-normal;
    z-index: 5;
    transform: translateY(-50%);

    & ~ .textarea__field {
      &::placeholder {
        transition: visibility 0.2s ease-in-out;
        visibility: hidden;
      }
    }

    &:has(~ .textarea__field:not(:placeholder-shown)) {
      @include transform-up;
    }
  }

  &__field {
    flex-basis: 100%;
    outline: none;
    font-family: inherit;
    border: none;
    overflow: auto;
    resize: none;
    flex-grow: 1;
    color: var(--color-white);

    &.styling {
      &--default {
        height: 20rem;
        padding: 2rem 4rem;
        font-size: $font-normal;
        background-color: var(--color-input-half);
        border-radius: $border-radius-card;

        &:focus {
          outline: 2px solid var(--color-primary);
        }

        &::placeholder {
          color: var(--color-grey-800);
          font-family: inherit;
        }
      }

      &--none {
        background-color: transparent;
      }
    }

    @include respond(phone) {
      height: 24rem;
    }

    &--error {
      border: 2px solid $color-error;
      color: var(--color-error);
      margin-bottom: 0rem;
    }

    &--error::placeholder {
      color: var(--color-error);
    }
  }

  .error-msg {
    margin-left: 2rem;
    display: block;
    width: 100%;
  }
}
</style>
